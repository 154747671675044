import React from 'react'
import Row from 'antd/lib/grid/row'
import Col from 'antd/lib/grid/col'
import Input from 'antd/lib/input/Input'
import Typography from '@sinch-smb/typography'
import withFinalForm from '@sinch-smb/with-final-form'
import { useForm, Field, useFormState } from 'react-final-form'
import { FormattedMessage, useIntl } from 'react-intl'

import PersonaliseMessage from '../personalise-dropdown'
import withValidation from '../../hocs/with-validation'
import MessageBox from '../extension-contact/message-content-card/message-box'

import { useAppSelector } from '../../store/hooks'
import {
  getDetails, getMmsLimitSize, getQueryData, getUserSupportedFileTypes,
} from '../../redux/contact/extension-contact-selectors'

import {
  maximumSMS,
  validateFileSizeBasedOnType,
  validateFileTypes,
  validFileSize,
  validMediaFile,
} from '../../helpers/form-validations/form-validations'
import fileSizeFormat from '../../helpers/file-size-format/file-size-format'
import concatPersonalisedTextToContent from '../../helpers/concat-personalised-text-to-content/concat-personalised-text-to-content'
import { getContactUsLink } from '../../helpers/geo-ip/geo-ip'
import { TExtensionContactFormState } from '../extension-contact/extension-contact-types'
import { ACCEPTED_TYPES_NZ, VALID_TYPES_NZ } from '../../constants/file-types'
import FileUploadComp from '../input/file-upload-comp'

const InputComp = withValidation(withFinalForm()(Input))

const validations = (
  validFileTypes: string[],
  sizeLimit: Record<string, number>,
) => ([validateFileTypes(validFileTypes), validateFileSizeBasedOnType(sizeLimit)])

const validationsNZ = [
  validFileSize(410),
  validMediaFile(VALID_TYPES_NZ),
]

const getAttachmentLimitSizes = (originalSizes: Record<string, number>) => {
  // we assume that the proportion between the original file size and file size converted to Base64 is 133 %
  // the size for subject and content message is gotten as the existing approach
  const messageStringLimit = 55
  const calculateSize = (originalSize: number) => Math.floor((originalSize - messageStringLimit) * 0.7519)
  const limitSizes: Record<string, number> = {}
  Object.keys(originalSizes).forEach((key) => {
    limitSizes[key] = calculateSize(originalSizes[key])
  })
  return limitSizes
}

type TMmsContainer = {
  isMmsCapable: boolean
  isNZ: boolean
} & TExtensionContactFormState

const MmsContainer = (props: TMmsContainer) => {
  const [mmsFileSize, setMmsFileSize] = React.useState(0)
  const { change } = useForm()
  const { values } = useFormState()
  const limitSizes = useAppSelector(getMmsLimitSize)
  const fileTypes = useAppSelector(getUserSupportedFileTypes)
  const { supportedTemplateMergeFields } = useAppSelector(getDetails)
  const { otp, objectType, uuid } = useAppSelector(getQueryData)
  const intl = useIntl()

  const convertedLimitSize = getAttachmentLimitSizes(limitSizes)
  const largestSizeKB = Math.max(...Object.values(convertedLimitSize), 0)
  const largestSizeFormatted = fileSizeFormat(largestSizeKB * 1024, 0)
  const typesToText = `${fileTypes.accepted.filter((t) => !['jpeg', 'mpeg'].includes(t)).slice(0, -1).join(', ')} and ${fileTypes.accepted.slice(-1)}`

  const getMmsMetaInfo = (value: string|number, unit: string, types: string) => <FormattedMessage id="mms.meta-info" values={{ value, unit, types }} />

  const addPersonalisedTextToSubject = (value: string) => {
    change(
      'subject',
      concatPersonalisedTextToContent(`#${value}#`, values.subject),
    )
  }

  // TODO: need country code and timezone to return correct link
  const CONTACT_US = getContactUsLink()

  const fileValidations = props.isNZ ? validationsNZ : validations(fileTypes.valid, convertedLimitSize)
  const acceptedFileTypes = props.isNZ ? ACCEPTED_TYPES_NZ : fileTypes.accepted
  const validFileTypes = props.isNZ ? VALID_TYPES_NZ : fileTypes.valid

  return (
    <>
      <Typography className="gray">
        {getMmsMetaInfo(largestSizeFormatted.value, largestSizeFormatted.unit, typesToText)}
      </Typography>

      <Typography className="gray">
        <FormattedMessage
          id="mms-container.mms-rates"
          values={{
            contactUs: (
              <a
                href={CONTACT_US}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage id="mms-container.mms-rates.contact-us" />
              </a>
            ),
          }}
        />
      </Typography>
      <Row>
        <Col span={17} className="upload-container">
          <label htmlFor="mediaURL" className="label">
            <FormattedMessage id="mms-container.media-url.label" />
          </label>
          <Field
            name="mediaURL"
            id="mediaURL"
            validations={fileValidations}
            component={FileUploadComp as React.FC}
            isMmsCapable={props.isMmsCapable}
            setMmsFileSize={setMmsFileSize}
            acceptedFileTypes={acceptedFileTypes}
            validFileTypes={validFileTypes}
          />
        </Col>
      </Row>

      <Row>
        <Col span={17}>
          <label htmlFor="subject" className="label">
            <FormattedMessage id="mms-container.subject.label" />
          </label>
          <PersonaliseMessage
            className="personalise-subject"
            supportedTemplateMergeFields={supportedTemplateMergeFields as string[]}
            onChange={addPersonalisedTextToSubject}
          />
          <Field
            name="subject"
            component={InputComp as React.FC}
            placeholder={intl.formatMessage({ id: 'mms-container.subject.placeholder' })}
            validate={maximumSMS(64)}
          />
        </Col>
      </Row>

      <MessageBox
        {...props}
        otp={otp as string}
        uuid={uuid as string}
        objectType={objectType as string}
        type="MMS"
        mmsFileSize={mmsFileSize}
        replaceMessage={(newContent) => change('messageContentMMS', newContent)}
      />
    </>
  )
}

export default MmsContainer
