import { datadogRum } from '@datadog/browser-rum'

const initialize = () => datadogRum.init({
  applicationId: 'adce440d-454a-41b5-83d4-2d827645dd4c',
  clientToken: 'pube315e510a12e7d60bbf6fb11183b635a',
  site: 'datadoghq.com',
  service: 'integration-hubspot-extension',
  env: process.env.BUILD_ENV || 'development',
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 0,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
})

export default initialize
