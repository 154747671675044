import React from 'react'
import Input, { TextAreaProps } from 'antd/lib/input'

const { TextArea } = Input

const AntdTextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>((props, ref) => (
  <TextArea
    {...props}
    ref={ref}
  />
))

export default AntdTextArea
