import React, { FC, useState } from 'react'
import classNames from 'classnames'
import _get from 'lodash/get'
import Col from 'antd/lib/grid/col'
import Row from 'antd/lib/grid/row'
import Button from 'antd/lib/button'
import Dragger from 'antd/lib/upload/Dragger'
import { FormSubscription } from 'final-form'
import Typography from '@sinch-smb/typography'
import { FieldInputProps } from 'react-final-form'
import { UploadFile } from 'antd/lib/upload/interface'
import Upload, { RcFile, UploadChangeParam } from 'antd/lib/upload'
import Icon, { LoadingOutlined, CloseCircleTwoTone } from '@ant-design/icons'
import { FormattedMessage, useIntl } from 'react-intl'

import { TFormValidationFunction, commonValidations } from '../../helpers/form-validations/form-validations'
import fileToBase64 from '../../helpers/file-to-base64'

import { NON_IMAGE_PREVIEW } from '../../constants/file-types'
import { ReactComponent as UploadIcon } from '../../assets/img/icons/upload.svg'

import './file-upload.css'
import { useAppSelector } from '../../store/hooks'
import { getQueryData } from '../../redux/contact/extension-contact-selectors'

export type TFileUpload = {
  onRemoveFile?: (file: any) => void
  onChange?: (e: any) => void
  onUploadFile?: (e: any) => void
  validations?: TFormValidationFunction[]
  showThumbnail?: boolean
  input?: FieldInputProps<string>
  children?: React.ReactNode
  meta?: FormSubscription
  acceptedFileTypes: string[]
  validFileTypes: string[]
  setMmsFileSize?: React.Dispatch<React.SetStateAction<number>>
  customErrorMsg?: string
  setCustomErrorMsg?: React.Dispatch<React.SetStateAction<string|undefined>>
}

const imageUploadUri = `${process.env.REACT_APP_LAMBDA_DOMAIN as string}/webhook/workflows/mms`

function FileUpload(props: TFileUpload) {
  const [stateFile, setStateFile] = useState<UploadFile>()
  const [fileStatus, setFileStatus] = useState<string>('')
  const {
    objectType,
    otp,
    uuid,
  } = useAppSelector(getQueryData)
  const intl = useIntl()

  React.useEffect(() => {
    if (!props.input?.value && stateFile) {
      setStateFile(undefined)
    }
  }, [props.input?.value, stateFile])

  const beforeUpload = (file: RcFile) => {
    let isValid = true
    if (props.validations) {
      const validationEror = commonValidations(props.validations)(file)
      if (validationEror) {
        isValid = false
        removeUploadedFile()
        props.setCustomErrorMsg?.(validationEror)
      } else {
        setStateFile(file)
        props.setCustomErrorMsg?.(undefined)
      }
    }
    return isValid || Upload.LIST_IGNORE
  }

  const onChange = async (info: UploadChangeParam) => {
    const { file } = info
    let mediaUrl = ''

    if (file?.status === 'uploading') {
      setFileStatus('uploading')
      props.setCustomErrorMsg?.(undefined)
    }

    if (file?.status === 'error' || file?.status === 'removed') {
      setFileStatus('')
      if (file.status === 'error') {
        props.setCustomErrorMsg?.(intl.formatMessage({ id: 'file-upload.custom-error.file-size-too-large' }, { fileType: file.type }))
      }
    }

    if (file?.status === 'done' || file?.status === 'success') {
      const encodedContent = await fileToBase64(file.originFileObj as RcFile) as string

      mediaUrl = _get(file, ['response', 'mediaURL'], '') as string
        || encodedContent

      props.input?.onChange?.(mediaUrl)
      props.setMmsFileSize?.(encodedContent.length)
      setFileStatus('done')
    }

    setStateFile(file as RcFile)
  }

  const removeUploadedFile = (e?: { stopPropagation: () => void}) => {
    e?.stopPropagation()
    setStateFile(undefined)
    props.input?.onChange?.(undefined)
    props.setMmsFileSize?.(0)
    setFileStatus('')
  }

  const renderIcon = () => {
    let IconComponent = <Icon component={UploadIcon} className="icon" />

    if (fileStatus === 'uploading') {
      IconComponent = <Icon component={LoadingOutlined as FC<React.SVGProps<SVGSVGElement>>} className="icon" />
    }

    if (fileStatus === 'done') {
      let thumbnail = props.input?.value as string
      if (props.validFileTypes.includes(stateFile?.type as string)) {
        thumbnail = NON_IMAGE_PREVIEW[stateFile?.type as never] || props.input?.value
      }
      IconComponent = <img className="thumbnail" src={thumbnail} alt="thumbnail" />
    }

    return (
      <Col className="flexVerticalCenter">
        { IconComponent }
      </Col>
    )
  }

  const renderDescription = () => {
    if (stateFile?.fileName) {
      return <Typography dataTest="file-name">{stateFile.fileName}</Typography>
    }
    return (
      <>
        <Typography>
          <FormattedMessage id="file-upload.description.upload-file" />
        </Typography>
        <Typography>
          <FormattedMessage id="file-upload.description.drag-n-drop" />
        </Typography>
        {
          props.acceptedFileTypes?.length
          && props.acceptedFileTypes.length > 0
          && (
            <Typography variant="caption">
              <FormattedMessage
                id="file-upload.description.accepted-file-types"
                values={{
                  fileTypes: props.acceptedFileTypes.filter((type) => !['jpeg', 'mpeg'].includes(type)).join(', '),
                }}
              />
            </Typography>
          )
        }
      </>
    )
  }

  const hasError = (props.meta?.touched && props.meta?.error)
    ? props.meta.error
    : (!!props.customErrorMsg)

  return (
    <Dragger
      accept={props.acceptedFileTypes?.map((fileType: string) => `.${fileType}`).join(', ')}
      beforeUpload={beforeUpload}
      onChange={onChange}
      multiple={false}
      id="mediaURL"
      className={classNames('media-upload', { error: hasError })}
      showUploadList={false}
      headers={{
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        'X-Requested-With': null!,
      }}
      action={
        `${imageUploadUri}/upload/${objectType}/${uuid}?otp=${otp}`
      }
    >
      <div className="upload-container">
        <Row justify="space-between">
          <Col>
            <Row justify="start" gutter={16}>
              <Col className="flexVerticalCenter">
                {renderIcon()}
              </Col>
              <Col className="flexVerticalCenter gray text">
                {renderDescription()}
              </Col>
            </Row>
          </Col>
          {
            stateFile?.status === 'done'
            && (
              <Col className="flexVerticalCenter">
                <Button
                  className="remove-file-btn"
                  name="remove-file"
                  onClick={removeUploadedFile}
                >
                  <Row
                    gutter={4}
                    className="flexHorizontalCenter"
                  >
                    <Col>
                      <CloseCircleTwoTone />
                    </Col>
                    <Col>
                      <Typography>
                        <span>
                          <FormattedMessage id="file-upload.remove-file" />
                        </span>
                      </Typography>
                    </Col>
                  </Row>
                </Button>
              </Col>
            )
          }
        </Row>
      </div>
    </Dragger>
  )
}

export default FileUpload
