import { ExclamationCircleFilled } from '@ant-design/icons'
import './trial-account-banner.css'
import { FormattedMessage } from 'react-intl'

function TrialAccountBanner() {
  return (
    <div className="trial-account-banner-wrapper">
      <div className="trial-account-banner" data-test="trial-account-banner">
        <ExclamationCircleFilled className="trial-account-icon" />
        <p>
          <FormattedMessage id="banner.trial.content" />
        </p>
      </div>
    </div>
  )
}

export default TrialAccountBanner
