import _get from 'lodash/get'
import _isString from 'lodash/isString'

import { CONTACT_US } from '../../constants/extension-contact'

const getGeoUrl = (urls: string|Record<string, string>, countryCode: string, timezone?: string) => {
  if (_isString(urls) || !urls) return urls

  const urlByCountryCode = _get(urls, countryCode.toLocaleUpperCase())
  if (urlByCountryCode !== undefined) return urlByCountryCode

  if (timezone && /^Europe/.test(timezone)) return urls.ETZ

  return urls.default
}

export const getContactUsLink = (countryCode = '', timezone?: string) => (
  getGeoUrl(CONTACT_US, countryCode, timezone)
)
