export type TOnChange = {
  onChange?: (arg: string) => void
}

async function insertValueAtCurrentCursor(el: HTMLTextAreaElement | undefined, insertedValue: string, props:TOnChange = {}) {
  if (!el) return
  const element = el
  const pos = element.selectionStart
  const front = (element.value).substring(0, pos)
  const back = (element.value).substring(pos, element.value.length)
  const updatedValue = `${front}${insertedValue}${back}`
  const updatedPos = pos + insertedValue.length
  if (typeof props.onChange === 'function') {
    await props.onChange(updatedValue)
  } else {
    element.value = updatedValue
  }
  element.selectionStart = updatedPos
  element.selectionEnd = updatedPos
  element.focus()
}

export default insertValueAtCurrentCursor
