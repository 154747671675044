export const TAB_SMS = 'TAB-SMS'
export const TAB_MMS = 'TAB-MMS'

export const DEFAULT_VALUE = '-'

export const CONTACT_US = {
  NZ: 'https://www.messagemedia.co.nz/contact-us',
  AU: 'https://www.messagemedia.com.au/contact-us',
  ETZ: 'https://www.messagemedia.co.uk/contact-us',
  default: 'https://messagemedia.com/us/contact',
}
export const DELIVERY_RECEIPTS = 'https://support.messagemedia.com/articles/57110-delivery-receipts'
export const UNICODE_SUPPORT = 'https://support.messagemedia.com/hc/en-us/articles/4413576543247'

export const PHONE_NUMBERS_FORMAT = {
  original: '',
  INTERNATIONAL: '',
  E164: '',
}

export const STANDARD_CHARACTER = 160
export const CHARACTERS_PER_PART = 153
