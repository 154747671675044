import React from 'react'
import Input from 'antd/lib/input'
import Radio, { RadioChangeEvent } from 'antd/lib/radio'
import { FormattedMessage } from 'react-intl'
import { TFields } from '../extension-contact-types'

type TSelectRecipientPhoneProps = {
  default?: string
  fields?: TFields
  displayValue?: string
  input?: {
    onChange?: (args: string) => void
    value?: string
  }
  disabled?: boolean
  children?: React.ReactNode // eslint-disable-line
}

function SelectRecipientPhone(props: TSelectRecipientPhoneProps) {
  const {
    default: defaultRecipient,
    displayValue,
    fields,
    disabled,
  } = props

  const handleChange = (e: RadioChangeEvent) => {
    props?.input?.onChange?.(e.target.value as string)
  }

  return (
    <>
      <Input
        className="recipients"
        disabled
        value={displayValue}
        defaultValue={defaultRecipient}
      />
      <Radio.Group
        className="recipients-options"
        value={props?.input?.value}
        disabled={disabled}
      >
        {
          fields
          && Object.keys(fields).map((key) => (
            <Radio
              value={key}
              key={key}
              onChange={handleChange}
            >
              <FormattedMessage id={`fields.${key}`} />
            </Radio>
          ))
        }
      </Radio.Group>
    </>
  )
}

export default SelectRecipientPhone
