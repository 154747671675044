import React from 'react'
import { FormattedMessage } from 'react-intl'

import {
  messageLength as smsMessageLength,
  multiMessageLength as smsMultiMessageLength,
} from '../sms-counter/sms-counter'
import { DELIVERY_RECEIPTS } from '../../constants/extension-contact'

export const nonUnicodeMessage = (messageLength: number, multiMessageLength: number) => (
  <span>
    <FormattedMessage
      id="count-description.default"
      values={{
        messageLength,
        multiMessageLength,
        learnMore: (
          <a href={DELIVERY_RECEIPTS} target="_blank">
            <FormattedMessage id="count-description.default.learn-more" />
          </a>
        ),
      }}
    />
  </span>
)
const getCountDescription = ({
  isExtended = false,
  messageLength = smsMessageLength.GSM_7BIT,
  multiMessageLength = smsMultiMessageLength.GSM_7BIT,
} = {}) => {
  if (isExtended) {
    return (
      <span style={{ whiteSpace: 'pre-line' }}>
        <FormattedMessage
          id="count-description.unicode"
          values={{
            messageLength,
            multiMessageLength,
          }}
        />
      </span>
    )
  }
  return nonUnicodeMessage(messageLength, multiMessageLength)
}

export default getCountDescription
