import { TSendersId, TSenderId } from '../../ui/extension-contact/extension-contact-types'

const TFNStatusForVerification = ['unverified', 'rejected']

export const isATfnRequiredNumber = (senderId: string) => {
  let exists = false
  const extensions = ['+1']
  extensions.forEach((extension) => {
    if (senderId.includes(extension)) {
      exists = true
    }
  })
  return exists
}

export const needToVerifyTFN = (senderId: TSenderId) => {
  if (isATfnRequiredNumber(senderId.number)) {
    const tfnStatus = senderId.TFNStatus ? senderId.TFNStatus.toLowerCase() : ''
    if (TFNStatusForVerification.includes(tfnStatus)) return true
  }
  return false
}

const isVerifiedTFN = (senderIds: TSendersId, senderIdNumber?: string) => {
  if (!senderIdNumber) return true
  if (!isATfnRequiredNumber(senderIdNumber)) return true
  const senderId = senderIds.find((a) => a.number === senderIdNumber)
  return senderId ? !needToVerifyTFN(senderId) : false
}

export default isVerifiedTFN
