import React from 'react'
import Layout from 'antd/lib/layout'
import Alert from 'antd/lib/alert'
import Spin from 'antd/lib/spin'

import ExtensionContactForm from './extension-contact-form'

import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { getContactDetails } from '../../redux/contact/extension-contact-actions'
import { getContact } from '../../redux/contact/extension-contact-selectors'

import useQuery from '../../hocs/use-query'

import './extension-contact.css'

const { Content } = Layout

function ExtensionContact() {
  const dispatch = useAppDispatch()
  const {
    ready, alertMessage, alertType,
  } = useAppSelector(getContact)

  const query = useQuery()
  const objectType = query.get('objectType') || 'CONTACT'
  const uuid = query.get('uuid') || '1234-1234'
  const otp = query.get('otp') || '1234'
  const qsContactId = query.get('qsContactId')
  const qsDealId = query.get('qsDealId')
  const qsUserId = query.get('qsUserId')

  React.useEffect(() => {
    if (!ready) {
      const fetchContactDetails = async () => {
        await dispatch(getContactDetails({
          objectType,
          uuid,
          otp,
          qsContactId,
          qsDealId,
          qsUserId,
        }))
      }
      fetchContactDetails()
    }
  }, []) // eslint-disable-line

  const renderContent = () => {
    if (!ready) {
      return <Spin size="large" className="spin" />
    }
    if (alertMessage) {
      return null
    }

    const formProps = {
      ...query,
      objectType,
      uuid,
      otp,
      qsContactId,
      qsDealId,
      qsUserId,
    }

    return (
      <ExtensionContactForm {...formProps} />
    )
  }

  return (
    <Layout className="layout">
      <Content>
        <div className="site-layout-content extension-contact">
          {
            alertMessage
            && (
              <Alert message={alertMessage} type={alertType} showIcon />
            )
          }
          {renderContent()}
        </div>
      </Content>
    </Layout>
  )
}

export default ExtensionContact
