const convertToIntlPhoneNumber = (phoneNumber: string) => {
  const chunks = []
  const charLength = 3

  for (let i = 0, charsLength = phoneNumber.length; i < charsLength; i += 3) {
    chunks.push(phoneNumber.substring(i, i + charLength))
  }

  return chunks.join(' ')
}

const formatPhoneNumber = (phoneNumber: string) => ({
  E164: phoneNumber || '',
  INTERNATIONAL: phoneNumber ? convertToIntlPhoneNumber(phoneNumber) : '',
  original: phoneNumber ? phoneNumber.slice(1) : '',
})

export default formatPhoneNumber
