import { combineReducers } from 'redux'
import contact from '../redux/contact'
import releaseFlags from '../redux/release-flags'

const reducers = {
  contact,
  releaseFlags,
}

export default combineReducers(reducers)
