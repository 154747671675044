import {
  TNumberFormat,
  TPhoneNumbers,
} from '../ui/extension-contact/extension-contact-types'

const getPhoneNumberFormat = (
  format: TNumberFormat = 'INTERNATIONAL',
  phoneNumbers: TPhoneNumbers,
  phoneNumberType: 'mobilephone' | 'phone' = 'mobilephone',
) => phoneNumbers[phoneNumberType][format] || '-'

export default getPhoneNumberFormat
