import React from 'react'
import Select from 'antd/lib/select'
import Icon from '@ant-design/icons'
import { FormattedMessage } from 'react-intl'

import BASE_PERSONALISATION_FIELDS from '../../constants/personalisation-field'

import { ReactComponent as PersonaliseIcon } from '../../assets/img/icons/personalisation.svg'

type TPersonaliseProps = {
  supportedTemplateMergeFields: string[]
  onChange?: (args: string) => void
  className?: string
  disabled?: boolean
}

function PersonaliseDropdown(props: TPersonaliseProps) {
  const {
    onChange,
    className,
    disabled,
  } = props
  const [value, setValue] = React.useState<string>()
  const {
    supportedTemplateMergeFields: options,
  } = props

  const handleChange = (selected: string) => {
    onChange?.(selected)
    setValue(selected)
    setTimeout(() => setValue(undefined), 1000)
  }

  const selectOptions = BASE_PERSONALISATION_FIELDS.filter((o) => options.includes(`#${o.value}#`))

  const classNames = [className, 'personalise-dropdown']
    .filter(Boolean)
    .join(' ')

  return (
    <Select
      className={classNames}
      placeholder={(
        <div className="personalise-placeholder">
          {' '}
          <Icon component={PersonaliseIcon} />
          {' '}
          <span>
            <FormattedMessage id="personalise.placeholder" />
          </span>
        </div>
      )}
      value={value}
      bordered={false}
      onChange={handleChange}
      disabled={disabled}
    >
      {
        selectOptions.map((selectOption) => (
          <Select.Option value={selectOption.value}>
            <FormattedMessage id={selectOption.label} />
          </Select.Option>
        ))
      }
    </Select>
  )
}

export default PersonaliseDropdown
