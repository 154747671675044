import _3gp from '../assets/mms/3gp.png'
import _avi from '../assets/mms/avi.png'
import _mp3 from '../assets/mms/mp3.png'
import _mp4 from '../assets/mms/mp4.png'
import _mpg from '../assets/mms/mpg.png'
import _wav from '../assets/mms/wav.png'
import _wmv from '../assets/mms/wmv.png'
import _pdf from '../assets/mms/pdf.png'
import _vcf from '../assets/mms/vcf.png'

export const NON_IMAGE_PREVIEW = {
  'audio/mpeg': _mp3,
  'audio/wav': _wav,
  'audio/x-wav': _wav,
  'video/3gpp': _3gp,
  'video/mp4': _mp4,
  'video/mpeg': _mpg,
  'video/x-msvideo': _avi,
  'video/avi': _avi,
  'video/x-ms-wmv': _wmv,
  'video/x-ms-asf': _wmv,
  'application/pdf': _pdf,
  'text/vcard': _vcf,
}
export const NON_IMAGE_PREVIEW_ALT = {
  mp3: _mp3,
  wav: _wav,
  '3gp': _3gp,
  mp4: _mp4,
  mpeg: _mpg,
  mpg: _mpg,
  avi: _avi,
  wmv: _wmv,
  pdf: _pdf,
  vcf: _vcf,
}

export const ACCEPTED_IMAGE_TYPES = ['jpg', 'jpeg', 'png', 'gif'] as const
export const VALID_IMAGE_TYPES = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'] as const

export const ACCEPTED_AUDIO_TYPES = ['mp3', 'wav'] as const
export const VALID_AUDIO_TYPES = ['audio/mpeg', 'audio/wav', 'audio/x-wav'] as const

export const ACCEPTED_VIDEO_TYPES = ['3gp', 'mp4', 'mpeg', 'mpg', 'avi', 'wmv'] as const
export const VALID_VIDEO_TYPES = ['video/3gpp', 'video/mp4', 'video/mpeg', 'video/x-msvideo', 'video/avi', 'video/x-ms-wmv', 'video/x-ms-asf'] as const

export const ACCEPTED_DOCUMENT_TYPES = ['pdf'] as const
export const VALID_DOCUMENT_TYPES = ['application/pdf'] as const

export const ACCEPTED_VCF_TYPES = ['vcf'] as const
export const VALID_VCF_TYPES = ['text/vcard', 'text/x-vcard'] as const

export const ACCEPTED_TYPES_NZ = ['jpg', 'jpeg', 'png', 'gif', 'pdf'] as const
export const VALID_TYPES_NZ = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif', 'application/pdf']

export const CSV_LIMIT_FILE_SIZE = 102400 as const
