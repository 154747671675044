type TEmptySpace = {
  word: string
}

const EmptySpace = ({
  word,
}: TEmptySpace) => {
  if (word === '') return <>&nbsp;</>

  /* eslint-disable-next-line react/jsx-no-useless-fragment */
  return <>{word}</>
}

export default EmptySpace
