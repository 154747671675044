import React from 'react'
import { useField } from 'react-final-form'
import { FormattedMessage } from 'react-intl'

import { useAppSelector } from '../../../store/hooks'
import { getDetails } from '../../../redux/contact/extension-contact-selectors'

import './sender-id-helper-text.css'

const HELPER_TEXT = {
  SHARED_NUMBER: 'helper-text.shared-number.text',
  BUSINESS_NAME: 'helper-text.business-name.text',
  DEDICATED_NUMBER: 'helper-text.dedicated-number.text',
  OWN_NUMBER: 'helper-text.own-number.text',
}

export const SENDER = {
  SHARED_NUMBER: 'SHARED_NUMBER',
  DEDICATED_NUMBER: 'DEDICATED_NUMBER',
  BUSINESS_NAME: 'BUSINESS_NAME',
  OWN_NUMBER: 'OWN_NUMBER',
  USER_OWN_NUMBER: 'USER_OWN_NUMBER',
  YOUR_NUMBER: 'YOUR_NUMBER',
}

const SenderIdHelperText = () => {
  const {
    senderIds,
  } = useAppSelector(getDetails)
  let displayText = null
  let title = ''

  const { input: { value } } = useField('senderId', { subscription: { value: true } })

  const selected = senderIds.find((a) => a.number === value)

  if (selected && selected.displayType === SENDER.SHARED_NUMBER) {
    displayText = HELPER_TEXT.SHARED_NUMBER
    title = 'helper-text.shared-number.title'
  } else if (selected && selected.displayType === SENDER.DEDICATED_NUMBER) {
    title = 'helper-text.dedicated-number.title'
    displayText = HELPER_TEXT.DEDICATED_NUMBER
  } else if (selected && selected.displayType === SENDER.BUSINESS_NAME) {
    title = 'helper-text.business-name.title'
    displayText = HELPER_TEXT.BUSINESS_NAME
  } else if (selected && (selected.displayType === SENDER.OWN_NUMBER || selected.displayType === SENDER.USER_OWN_NUMBER || selected.displayType === SENDER.YOUR_NUMBER)) {
    displayText = HELPER_TEXT.OWN_NUMBER
    title = 'helper-text.own-number.title'
  } else if (value && /^(?=.*[a-zA-Z-_])/.test(value) && value !== 'ACCOUNT_DEFAULT') {
    title = 'helper-text.business-name.title'
    displayText = HELPER_TEXT.BUSINESS_NAME
  }

  return displayText
    ? (
      <div className="helperText" id="vwo-sender-is-helper-text">
        <b>
          <FormattedMessage data-test="sender-id-title" id={title} />
        </b>
        <FormattedMessage data-test="sender-id-text" id={displayText} />
      </div>
    )
    : null
}

export default SenderIdHelperText
