import { Form } from 'antd'
import { ReactNode, useState } from 'react'
import { FormSubscription } from 'final-form'

export type TProps = {
  children?: ReactNode
  meta?: FormSubscription
}

const withValidation = <Props extends TProps>(Component: React.FC<Props>):React.FC<Props> => {
  const InnerComponent = (props: TProps) => {
    const [customErrorMsg, setCustomErrorMsg] = useState()
    const { children, meta } = props
    const hasError = (meta?.touched || meta?.modified) && meta?.error

    return (
      <Form.Item
        validateStatus={hasError || customErrorMsg ? 'error' : ''}
        help={(hasError || customErrorMsg) && (meta?.error || customErrorMsg)}
      >
        <Component
          {...props as Props}
          customErrorMsg={customErrorMsg}
          setCustomErrorMsg={setCustomErrorMsg}
        >
          {children}
        </Component>
      </Form.Item>
    )
  }
  return InnerComponent
}
export default withValidation
