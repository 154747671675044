import { ReactNode } from 'react'
import { RcFile } from 'antd/lib/upload'
import _get from 'lodash/get'
import _trim from 'lodash/trim'
import _uniq from 'lodash/uniq'
import { FormattedMessage } from 'react-intl'

import smsCounter, { detectEncoding, smsConfig } from '../sms-counter/sms-counter'
import {
  TLayoutStateData,
  TCheckedTemplateMergeFields,
  TPhoneNumbers,
} from '../../ui/extension-contact/extension-contact-types'
import {
  VALID_IMAGE_TYPES,
  VALID_AUDIO_TYPES,
  VALID_VCF_TYPES,
  VALID_VIDEO_TYPES,
} from '../../constants/file-types'

import { loosenUrlRegex } from '../url/url'
import getPhoneNumberFormat from '../get-phone-number-format'

export type TFormValidationFunction = (arg: string | Record<string, unknown> | RcFile) => string | undefined
export type TValidator = (value?: string) => string | number | ReactNode | undefined

const validationMessages = {
  arrayMaximum: (number: number, label: string) => (
    <FormattedMessage
      id="validation.array.maximum"
      values={{
        number,
        label,
      }}
    />
  ),
  unicode: (supportLink: string) => (
    <FormattedMessage
      id="validation.unicode.exists"
      values={{
        learnMore: (
          <a href={supportLink} target="_blank">
            <FormattedMessage id="validation.unicode.exists.learn-more" />
          </a>
        ),
      }}
    />
  ),
}

export const fieldRequired = (fieldName?: string) => (value?: string | number) => {
  if (value && /\S/.test(value.toString())) {
    return undefined
  }
  return <FormattedMessage id="validation.field.required" values={{ field: fieldName || 'Input' }} />
}

export const maximumItemInArray = (number: number, itemlabel = 'items') => (val: string[]) => (val?.length > number ? validationMessages.arrayMaximum(number, itemlabel) : undefined)

export const minimumCharacter = (number: number) => (value?: string | number) => (
  value === undefined || (RegExp(`^.{${number},}$`, 'g').test(_trim(value.toString())))
    ? undefined
    : <FormattedMessage id="validation.character.minimum" values={{ number }} />
)

export const maximumCharacter = (number: number) => (value?: string | number) => (
  _get(value, 'length', 0) <= number
    ? undefined
    : <FormattedMessage id="validation.character.maximum" values={{ number }} />
)

export const email = (value?: string | number) => {
  if (value && !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(value.toString())) {
    return <FormattedMessage id="validation.email.invalid" />
  }
  return undefined
}

export const phone = (value?: string | number) => {
  if (value && /^\+0/i.test(value.toString())) {
    return <FormattedMessage id="validation.phone.country-code.zero" />
  }
  if (value && !/^\+[1-9][0-9]{6,14}$/i.test(value.toString())) {
    return <FormattedMessage id="validation.phone.country-code.invalid" />
  }

  return undefined
}

export const validFileSize = (kb: number) => (file?: string | Record<string, unknown> | RcFile) => {
  if (typeof file === 'string') {
    return <FormattedMessage id="validation.file.v1.size.input.invalid" />
  }

  if (file && file.size && file.size as number / 1024 >= kb) {
    return <FormattedMessage id="validation.file.v1.size.limit.exceeded" values={{ kb }} />
  }

  return undefined
}

export const validMediaFile = (validMedias: string[]) => (file?: string | Record<string, unknown> | RcFile) => {
  if (typeof file === 'string') {
    return <FormattedMessage id="validation.file.v1.type.input.invalid" />
  }

  if (file && file.type && !validMedias.includes(file.type as string)) {
    return <FormattedMessage id="validation.file.v1.type.invalid" />
  }

  return undefined
}

export const commonValidations = (validations?: TFormValidationFunction[]) => (item: string | Record<string, unknown> | RcFile) => (
  validations
    ? validations.reduce((result: string | undefined, validate) => result || validate(item), undefined)
    : undefined
)

export const maximumSMS = (number: number, urlShortener?: boolean) => (text?: string) => {
  const { length } = smsCounter(text, urlShortener)
  return length > number
    ? <FormattedMessage id="validation.sms.maximum" values={{ number }} />
    : undefined
}

export const composeValidators = (validators: TValidator[]) => (value?: string) => validators.reduce<string | number | ReactNode | undefined>(
  (error, validator) => error || validator(value),
  undefined,
)

export const isValidUnicode = (supportLink: string) => (text: string) => {
  if (text && detectEncoding(text) === smsConfig.UTF16) {
    return validationMessages.unicode(supportLink)
  }
  return undefined
}

/**
 * Get property value that matches from hubspot template
 * @param state - state data
 * @param templateField - fields to match on hubspot
 * @param checkedFields - merge tags in current form that have or do not have value
 * @returns value of field
 */
export const getHubspotPropValue = (state: TLayoutStateData, templateField: string, checkedFields: TCheckedTemplateMergeFields) => {
  let propValue = _get(state, ['templateMergeFieldValues', templateField], '') as string
  const hasProperty = Object.prototype.hasOwnProperty.call(propValue, 'mobilephone')

  if (hasProperty) { // if #mobilenumber#
    propValue = _get(state, ['templateMergeFieldValues', templateField, 'mobilephone'], '') as string
      || _get(state, ['templateMergeFieldValues', templateField, 'phone'], '') as string
  }
  if (!propValue) { // propValue no value
    checkedFields.fieldsWithNoValue.push(templateField)
    return ''
  }
  if (!checkedFields.hasValue) {
    Object.assign(checkedFields, { hasValue: true })
  }
  return propValue
}

export const validateMessageContent = (state: TLayoutStateData) => (messageContent: string | undefined) => {
  if (!messageContent || !messageContent.trim()) {
    return <FormattedMessage id="validation.message.empty" />
  }
  let errorMessage
  // validate message. should not contain unknown templateMergeFields
  const supportedTemplateMergeFields: string[] = _get(state, ['supportedTemplateMergeFields'], [])
  // match all hash words. Ex: #foobar#
  // Accepted Special Character(s): -
  const hashMatches = messageContent.match(/(#[-a-zA-Z|0-9]+#)/g) as string[]
  // Get all unknown hash words
  const templateMergeFieldToCheck: string[] = [] // supported template merge fields to check
  const unknownTemplateMergeFields: string[] = hashMatches && hashMatches.reduce((arr: string[], val) => {
    if (!supportedTemplateMergeFields.includes(val)) {
      arr.push(val)
    } else {
      templateMergeFieldToCheck.push(val) // inserted to be checked onSubmit
    }
    return arr
  }, [])

  const checkedTemplateMergeFields: TCheckedTemplateMergeFields = { // use object to pass as reference
    hasValue: false, // true if one of the fields has value. Only updated once
    fieldsWithNoValue: [], // array of fields with no value
  }
  let validatedMessageContent = messageContent // parsed messageContent
  templateMergeFieldToCheck.forEach((templateField: string) => {
    // replace all supported template merge fields
    validatedMessageContent = validatedMessageContent.replace(RegExp(templateField, 'g'),
      getHubspotPropValue(state, templateField, checkedTemplateMergeFields))
  })

  if (!validatedMessageContent.trim()) { // all supported fields empty and no other characters
    const isPlural = Math.abs(checkedTemplateMergeFields.fieldsWithNoValue.length) !== 1
    const mergeFields = checkedTemplateMergeFields.fieldsWithNoValue.join(', ')
    if (isPlural) {
      errorMessage = <FormattedMessage id="validation.message.empty-templates.other" values={{ mergeFields }} />
    } else {
      errorMessage = <FormattedMessage id="validation.message.empty-templates.one" values={{ mergeFields }} />
    }
  }

  if (unknownTemplateMergeFields && unknownTemplateMergeFields.length) { // contains unknown hash words
    const strUnknownTemplateMergeFields = _uniq(unknownTemplateMergeFields).join(', ')
    errorMessage = <FormattedMessage id="validation.message.unsupported-templates" values={{ mergeFields: strUnknownTemplateMergeFields }} />
  }

  return errorMessage
}

const urlValidation = (value: string, regex: RegExp) => (
  regex.test(value)
    ? undefined
    : <FormattedMessage id="validation.url.invalid" />
)

export const url = (value: string) => urlValidation(value, loosenUrlRegex)

export const getLimitSizeByType = (sizes: Record<string, number>, file: Record<string, never>) => {
  const STATIC_IMAGE_TYPES = VALID_IMAGE_TYPES.slice(0, -1)
  const GIF_TYPES = VALID_IMAGE_TYPES.slice(-1)

  if (STATIC_IMAGE_TYPES.includes(file.type)) {
    return _get(sizes, 'staticImage', 0)
  }
  if (GIF_TYPES.includes(file.type)) {
    return _get(sizes, 'gif', 0)
  }
  if (VALID_VIDEO_TYPES.includes(file.type)) {
    return _get(sizes, 'video', 0)
  }
  if (VALID_AUDIO_TYPES.includes(file.type)) {
    return _get(sizes, 'audio', 0)
  }
  if (VALID_VCF_TYPES.includes(file.type)) {
    return _get(sizes, 'vcard', 0)
  }

  return _get(sizes, 'others', 0)
}

export const validateFileSizeBasedOnType = (convertedLimitSize: Record<string, number>) => (file: Record<string, never>) => {
  const fileSize = _get(file, 'size', 0) / 1024
  const limitSize = getLimitSizeByType(convertedLimitSize, file)
  const limit = (limitSize < 1024) ? (`${limitSize} KB`) : (`${Math.floor(limitSize / 1024)} MB`)
  return limitSize > fileSize
    ? undefined
    : <FormattedMessage id="validation.file.v2.size" values={{ limit }} />
}

export const validateFileTypes = (types: string|string[]) => (file: Record<string, never>) => (
  (Array.isArray(types) ? types : [types]).includes(file.type)
    ? undefined
    : <FormattedMessage id="validation.file.v2.type.invalid" />
)

export const validatePhoneNumber = (phoneNumbers: TPhoneNumbers) => (phoneSource: string) => {
  const validNumber = getPhoneNumberFormat('E164', phoneNumbers, phoneSource as 'mobilephone'|'phone')
  if (!validNumber || validNumber === '-') return <FormattedMessage id="validation.phone.number.invalid" />
  return undefined
}
