import _isString from 'lodash/isString'

type TCharCounterWithUrlShortenerReturn = {
  length: number
  truncatedTxt: string
}

const URL_SHORTENER_LENGTH = 22

// http|https is required
export const urlRegex = /(\b(https?):\/\/[-A-Z0-9$+&@#()/%?=~_|[\]!:,.;]*[-A-Z0-9$+&@#()/%=~_|[\]])/gi

export const URL_REGEX_STR = '(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))(?::\\d{2,5})?(?:(\\/|\\?)\\S*)?'

/**
 * Extract url with http or https protocol from a string into an array of urls.
 * URLs with Hashtag (#) in query params (e.g.: https://mm.co/page?first=#name#)
 * are invalid url (More detail: https://url.spec.whatwg.org/#url-writing)
 * but it is valid for us as we use # in Message content -> Personalisation
 * @param {string} str the input string
 * @returns {array|null} an array of urls
 */
export const getUrlFromString = (str: string) => str.match(urlRegex)

/**
 * String length counter with url shortener,
 * url length in string is left intact if smaller than URL_SHORTENER_LENGTH (default 22)
 * and equal to 22 if bigger
 * @param {string} str the input string
 * @param {number} shortenerLength url shortener length
 * @returns {number} string length with url shortener
 */
export const charCounterWithUrlShortener = (str: string, shortenerLength = URL_SHORTENER_LENGTH): TCharCounterWithUrlShortenerReturn => {
  const urls = getUrlFromString(str)

  let { length } = str
  let truncatedTxt = str

  if (urls) {
    urls.forEach((url) => {
      truncatedTxt = truncatedTxt.replace(url, '')
      length += shortenerLength - url.length
    })
  }

  return { length, truncatedTxt }
}

// loosen url are those not contain http or www, are still considered as url
export const LOOSEN_URL_REGEX_STR = `(?:(?:https?):\\/\\/)?${URL_REGEX_STR}`
export const loosenUrlRegex = new RegExp(`^${LOOSEN_URL_REGEX_STR}$`, 'i')

export const hasUrl = (value = '') => _isString(value) && !!value.split(/\s+/).find((u) => u.match(loosenUrlRegex))

export const URL_SHORTENER_TEMPLATE = 'https://nxt.to/1234567'
export const SHORTENER_PLACEHOLDER = 'https://nxt.to/*******'
