import { createSelector } from 'reselect'
import _get from 'lodash/get'
import formatPhoneNumber from '../../helpers/format-phone-number/format-phone-number'
import { RootState } from '../../store/store'
import { TLayoutState, TLayoutStateData } from '../../ui/extension-contact/extension-contact-types'
import {
  ACCEPTED_IMAGE_TYPES,
  VALID_IMAGE_TYPES,
  ACCEPTED_AUDIO_TYPES,
  VALID_AUDIO_TYPES,
  ACCEPTED_DOCUMENT_TYPES,
  VALID_DOCUMENT_TYPES,
  ACCEPTED_VCF_TYPES,
  VALID_VCF_TYPES,
  ACCEPTED_VIDEO_TYPES,
  VALID_VIDEO_TYPES,
} from '../../constants/file-types'
import {
  MESSAGING_SEND_MMS,
  MESSAGING_SEND_MMS_AUDIO,
  MESSAGING_SEND_MMS_DOCUMENT,
  MESSAGING_SEND_MMS_NZ,
  MESSAGING_SEND_MMS_VCARD,
  MESSAGING_SEND_MMS_VIDEO,
} from '../../constants/feature-list'

export type TState = {
  contact?: TLayoutState
}

export const getContact = (state: TState) => state.contact || {} as TLayoutState

export const getDetails = createSelector(
  getContact,
  ({ contactDetails }) => contactDetails || {},
)

export const getSurveyDone = createSelector(
  getDetails,
  ({ qsSurveyDone }) => qsSurveyDone,
)

export const getEnableShortTrackableLinks = createSelector(
  getDetails,
  ({ enableShortTrackableLinks }) => enableShortTrackableLinks || false,
)

export const getPhoneNumbers = (state: RootState) => {
  const { contact, releaseFlags } = state
  const {
    userPhoneNumber,
    trialUser,
    phoneNumbers,
  } = contact.contactDetails as TLayoutStateData

  return releaseFlags.selfServeOnboarding && trialUser
    ? { mobilephone: formatPhoneNumber(''), phone: formatPhoneNumber(userPhoneNumber as string) }
    : (phoneNumbers || {})
}

export const getSenderIds = createSelector(
  getDetails,
  ({ senderIds }) => senderIds || [],
)

export const getIsTrialUser = createSelector(
  getDetails,
  ({ trialUser }) => trialUser || false,
)

export const getTFNTrialContent = createSelector(
  getDetails,
  ({ TFNTrialContent }) => TFNTrialContent,
)

const hasFeature = (feature: string) => (featureList: string[]) => Array.isArray(featureList) && featureList.some((featureItem) => featureItem === feature)

export const getMmsLimitSize = createSelector(
  getDetails,
  ({ mms, featureSet }) => {
    const mmsSize = _get(mms, 'sizeLimit', {})
    const features = _get(featureSet, 'features', [])
    const isVideoSupported = hasFeature(MESSAGING_SEND_MMS_VIDEO)(features)
    const isAudioSupported = hasFeature(MESSAGING_SEND_MMS_AUDIO)(features)
    const isDocumentSupported = hasFeature(MESSAGING_SEND_MMS_DOCUMENT)(features)
    const isVcfSupported = hasFeature(MESSAGING_SEND_MMS_VCARD)(features)

    return [{
      permission: true, property: 'staticImage',
    }, {
      permission: true, property: 'gif',
    }, {
      permission: isVideoSupported, property: 'video',
    }, {
      permission: isAudioSupported, property: 'audio',
    }, {
      permission: isDocumentSupported, property: 'others',
    }, {
      permission: isVcfSupported, property: 'vcard',
    }].reduce((acc, cur) => (
      cur.permission ? { ...acc, [cur.property]: Math.floor(_get(mmsSize, cur.property, 0) / 1024) } : acc
    ), {})
  },
)

export const getUserSupportedFileTypes = createSelector(
  getDetails,
  ({ featureSet }) => {
    let acceptedFileTypes:string[] = [...ACCEPTED_IMAGE_TYPES]
    let validFileTypes:string[] = [...VALID_IMAGE_TYPES]
    const features = _get(featureSet, 'features', [])

    const isVideoSupported = hasFeature(MESSAGING_SEND_MMS_VIDEO)(features)
    const isAudioSupported = hasFeature(MESSAGING_SEND_MMS_AUDIO)(features)
    const isDocumentSupported = hasFeature(MESSAGING_SEND_MMS_DOCUMENT)(features)
    const isVcfSupported = hasFeature(MESSAGING_SEND_MMS_VCARD)(features)

    if (isAudioSupported) {
      acceptedFileTypes = [...acceptedFileTypes, ...ACCEPTED_AUDIO_TYPES]
      validFileTypes = [...validFileTypes, ...VALID_AUDIO_TYPES]
    }
    if (isVideoSupported) {
      acceptedFileTypes = [...acceptedFileTypes, ...ACCEPTED_VIDEO_TYPES]
      validFileTypes = [...validFileTypes, ...VALID_VIDEO_TYPES]
    }
    if (isDocumentSupported) {
      acceptedFileTypes = [...acceptedFileTypes, ...ACCEPTED_DOCUMENT_TYPES]
      validFileTypes = [...validFileTypes, ...VALID_DOCUMENT_TYPES]
    }
    if (isVcfSupported) {
      acceptedFileTypes = [...acceptedFileTypes, ...ACCEPTED_VCF_TYPES]
      validFileTypes = [...validFileTypes, ...VALID_VCF_TYPES]
    }

    return {
      accepted: acceptedFileTypes,
      valid: validFileTypes,
    }
  },
)

export const getUserMmsFeature = createSelector(
  getDetails,
  ({ featureSet }) => {
    const hasMms = featureSet?.features.some((feature) => feature === MESSAGING_SEND_MMS)
    const hasNzMms = featureSet?.features.some((feature) => feature === MESSAGING_SEND_MMS_NZ)
    return {
      hasMms,
      hasNzMms,
    }
  },
)

export const getQueryData = createSelector(
  getDetails,
  ({ otp, uuid, objectType }) => ({ otp, uuid, objectType }),
)
