import { Dispatch, AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import ExtensionContactApi from '../../apis/extension-contact-api'
import * as types from './extension-contact-actions-types'
import { TQueryState, TAlert, TSendMessageParams } from '../../ui/extension-contact/extension-contact-types'
import { TState } from './extension-contact-selectors'
import generateUrlSearchParams from '../../helpers/generate-url-search-params'

type TDispatch = ThunkDispatch<TState, void, AnyAction>

export const getContactDetails = (query: TQueryState) => async (dispatch: Dispatch) => {
  dispatch({
    type: types.GET_CONTACT_DETAILS.REQUEST,
  })

  const search = generateUrlSearchParams(query)

  try {
    const response = await ExtensionContactApi.getContactDetails(query, search)

    dispatch({
      type: types.GET_CONTACT_DETAILS.SUCCESS,
      payload: {
        ...await response.json(),
        ...query,
      },
    })
  } catch (error) {
    dispatch({
      type: types.GET_CONTACT_DETAILS.FAILURE,
    })
    throw error
  }
}

export const sendMessage = ({ query, body }: TSendMessageParams) => async (dispatch: TDispatch) => {
  const search = generateUrlSearchParams(query)

  try {
    await ExtensionContactApi.sendMessage({ query, body }, search)
  } catch (error) {
    dispatch(setAlert({
      message: 'Unable to send message, please close the pop-up and try again.',
      type: 'error',
    }))
    throw error
  }
}

export const setAlert = ({ message, type }: TAlert) => (dispatch: Dispatch) => {
  dispatch({
    type: types.SET_ALERT,
    payload: {
      message,
      type,
    },
  })
}
