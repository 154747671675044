import React from 'react'
import UrlShortener from '../url-shortener'

import { url as urlValidator } from '../../../helpers/form-validations/form-validations'
import { URL_SHORTENER_TEMPLATE } from '../../../helpers/url/url'

import './styles.css'

type TLinkerProps = {
  word: string
  children: React.ReactNode
}

const LinkerView = ({
  word,
  children,
}: TLinkerProps):JSX.Element => {
  const isWordIncludeUrlShortener = word.includes(URL_SHORTENER_TEMPLATE)
  if (urlValidator(word) && !isWordIncludeUrlShortener) return children as JSX.Element
  if (urlValidator(word) && isWordIncludeUrlShortener) return <UrlShortener isWordIncludeUrlShortener={isWordIncludeUrlShortener} url={word} />

  return <span className="link"><UrlShortener isWordIncludeUrlShortener={isWordIncludeUrlShortener} url={word} /></span>
}

export default LinkerView
