import React from 'react'
import { Base64 } from 'js-base64'
import { FormattedMessage } from 'react-intl'

import fileSizeFormat from '../../helpers/file-size-format/file-size-format'

import './mms-message-counter.css'

type TMMSProps = {
  content: string
  mmsFileSize: number
  mmsSubjectSize: number
}

type TMMSMessageCounterProps = {
  name: string
  mmsProps: TMMSProps
}

const OVER_HEAD_SIZE = 200 // bytes

export const MMSMessageCounterView = (props: TMMSMessageCounterProps) => {
  const { mmsFileSize, mmsSubjectSize, content } = props.mmsProps
  const contentSize = Base64.encode(content).length
  const totalSize = mmsFileSize + contentSize + mmsSubjectSize
  const size = fileSizeFormat(totalSize > 0 ? totalSize + OVER_HEAD_SIZE : totalSize, 0)
  return (
    <div className="mms-message-counter" data-test={props.name}>
      <span className="letterCount">
        <FormattedMessage
          id="mms-message-counter.letter.count"
          values={{
            sizeValue: <span className="highlight">{size.value}</span>,
            sizeUnit: size.unit,
            size: <span className="highlight">600 </span>,
          }}
        />
      </span>
    </div>
  )
}

export default MMSMessageCounterView
