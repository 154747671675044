const fileSizeFormat = (bytes = 0, decimals = 2) => {
  if (bytes > 1) {
    const oneK = 1024
    const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const sizeInK = Math.floor(Math.log(bytes) / Math.log(oneK))
    const value = parseFloat((bytes / (oneK ** sizeInK)).toFixed(Math.abs(decimals)))

    return {
      value,
      unit: units[sizeInK],
    }
  }

  return {
    value: bytes,
    unit: 'Byte',
  }
}

export default fileSizeFormat
