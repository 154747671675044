type TSearchObject = {
  otp: string
  qsContactId?: string | null
  qsDealId?: string | null
  qsUserId?: string | null
}

const generateUrlSearchParams = (searchObject: TSearchObject) => {
  const search = new URLSearchParams({ otp: searchObject.otp })

  if (searchObject.qsContactId) search.append('qsContactId', searchObject.qsContactId)
  if (searchObject.qsDealId) search.append('qsDealId', searchObject.qsDealId)
  if (searchObject.qsUserId) search.append('qsUserId', searchObject.qsUserId)

  return search
}

export default generateUrlSearchParams
