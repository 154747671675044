import { AnyAction } from 'redux'
import _get from 'lodash/get'

import * as types from './extension-contact-actions-types'
import { TLayoutState } from '../../ui/extension-contact/extension-contact-types'

import { PHONE_NUMBERS_FORMAT } from '../../constants/extension-contact'

export const initialState = {
  ready: false,
  thumbnail: undefined,
  contactDetails: {
    fields: {},
    defaultSenderId: '',
    default: 'mobilephone',
    messageTemplates: {
      templates: [],
    },
    senderIds: [],
    phoneNumbers: {
      mobilephone: {
        ...PHONE_NUMBERS_FORMAT,
      },
      phone: {
        ...PHONE_NUMBERS_FORMAT,
      },
    },
    enableShortTrackableLinks: true,
    trialUser: false,
  },
}

export default function contactReducer(state: TLayoutState = initialState, action: AnyAction) {
  switch (action.type) {
    case types.GET_CONTACT_DETAILS.REQUEST:
      return {
        ...state,
        ready: false,
      }
    case types.GET_CONTACT_DETAILS.SUCCESS:
      return {
        ...state,
        ready: true,
        contactDetails: _get(action, 'payload', {}),
      }
    case types.GET_CONTACT_DETAILS.FAILURE:
      return {
        ...state,
        ready: true,
        alertMessage: 'Unable to verify request, please close the window and try again.',
        alertType: 'error',
      }
    case types.SET_ALERT:
      return {
        ...state,
        alertMessage: _get(action, 'payload.message', ''),
        alertType: _get(action, 'payload.type', ''),
      }
    default: return state
  }
}
