import smsCounter from './sms-counter'

export const messageCounter = (message: string, enableShortTrackableLinks = false) => {
  const messageToCount = message

  const counter = smsCounter(messageToCount, enableShortTrackableLinks)

  return counter
}

export default messageCounter
