// import { useState } from 'react'
import classNames from 'classnames'
import { PopupButton } from '@typeform/embed-react'
import { CloseOutlined } from '@ant-design/icons'
import Button from 'antd/lib/button'
import { FormattedMessage } from 'react-intl'

import './typeform-widget.css'

function TypeformWidget({
  typeformId,
  className,
  onSurveyCompleteCallback,
  onSurveyClose,
  onClickCloseTypeformButton,
}: {
  typeformId: string,
  className?: string,
  onSurveyCompleteCallback: (formId?: string, responseId?: string) => Promise<void>
  onSurveyClose: () => void,
  onClickCloseTypeformButton: () => void,
}) {
  const onSubmit = async ({ formId, responseId }: { formId: string, responseId: string}) => {
    await onSurveyCompleteCallback(formId, responseId)
  }

  return (
    <>
      <PopupButton
        buttonProps={{
          type: 'button',
        }}
        id={typeformId}
        className={classNames('typeformWidget', className)}
        autoClose={false}
        onSubmit={onSubmit}
        onClose={onSurveyClose}
      >
        <b>
          <FormattedMessage id="typeform.button.text" />
        </b>
      </PopupButton>
      <Button
        onClick={onClickCloseTypeformButton}
        htmlType="button"
        type="text"
        className="button-icon"
        data-test="hide-typeform"
      >
        <CloseOutlined className="closeIcon" />
      </Button>
    </>
  )
}

export default TypeformWidget
