import { URL_SHORTENER_TEMPLATE, SHORTENER_PLACEHOLDER, getUrlFromString } from '../../../helpers/url/url'
import { getEnableShortTrackableLinks } from '../../../redux/contact/extension-contact-selectors'
import { useAppSelector } from '../../../store/hooks'

type TUrlShortener = {
  url: string
  isWordIncludeUrlShortener?: boolean,
}

const UrlShortener = ({
  url,
  isWordIncludeUrlShortener = false,
}: TUrlShortener) => {
  const hasUrlShortener = useAppSelector(getEnableShortTrackableLinks)
  /* eslint-disable react/jsx-no-useless-fragment */
  return (
    <>
      {
        hasUrlShortener && getUrlFromString(url) && !isWordIncludeUrlShortener
          ? SHORTENER_PLACEHOLDER
          : url.replace(URL_SHORTENER_TEMPLATE, SHORTENER_PLACEHOLDER)
      }
    </>
  )
}

export default UrlShortener
