import {
  TMobilePhone,
  TTemplateMergeFieldValues,
} from '../../ui/extension-contact/extension-contact-types'

export type TParseMessageContent = {
  phoneSource?: TMobilePhone
  templateMergeFieldValues?: TTemplateMergeFieldValues,
  message?: string
}

const mergeTagParser = (tagMapper: Record<string, string>, regex: RegExp, flag = 'g') => (word: string) => {
  const cloneRegex = new RegExp(regex.source, flag)

  let newWord = word.slice()
  let regexMatch = cloneRegex.exec(newWord)

  while (regexMatch !== null) {
    newWord = newWord.replace(regexMatch[0], tagMapper[regexMatch[0]])
    cloneRegex.lastIndex = 0
    regexMatch = cloneRegex.exec(newWord)
  }
  return newWord
}

const parseTagsForPreview = ({
  phoneSource,
  templateMergeFieldValues,
  message,
}: TParseMessageContent) => (
  message
  && message.length > 0
  && templateMergeFieldValues
    ? message.split('\n').map((sentence) => {
      const tags = Object.keys(templateMergeFieldValues)
      const hashRegex = new RegExp(`(${tags.join('|')})`, 'g')
      const mergeTags = {
        ...templateMergeFieldValues,
        '#mobilenumber#': phoneSource
          ? templateMergeFieldValues['#mobilenumber#'][phoneSource] || ''
          : '',
      }
      const wordParser = mergeTagParser(mergeTags, hashRegex)
      const words = sentence.split(' ').map(wordParser)

      return words.join(' ')
    })
    : null
)

export default parseTagsForPreview
