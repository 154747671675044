import React, { useCallback, Fragment } from 'react'
import { useFormState } from 'react-final-form'
import Space from 'antd/lib/space'
import Typography from 'antd/lib/typography'
import _find from 'lodash/find'
import _isEmpty from 'lodash/isEmpty'
import _get from 'lodash/get'

import {
  TFormValues,
  TMobilePhone,
} from '../extension-contact-types'
import { TAB_SMS, DEFAULT_VALUE, TAB_MMS } from '../../../constants/extension-contact'
import parseTagsForPreview from '../../../helpers/parse-tags-for-preview/parse-tags-for-preview'

import { useAppSelector } from '../../../store/hooks'
import { getDetails, getUserMmsFeature, getUserSupportedFileTypes } from '../../../redux/contact/extension-contact-selectors'

import { ReactComponent as PhoneFrame } from '../../../assets/img/phone.svg'
import './phone-preview.css'
import Linker from '../linker'
import EmptySpace from '../empty-space'
import { NON_IMAGE_PREVIEW_ALT, ACCEPTED_TYPES_NZ } from '../../../constants/file-types'

type TPhonePreviewProps = {
  phoneSource?: TMobilePhone
}

const { Text } = Typography

function PhonePreview(props: TPhonePreviewProps) {
  const {
    phoneSource,
  } = props
  const { values } = useFormState<TFormValues>()
  const {
    tabMessageContent,
    messageContentSMS,
    messageContentMMS,
    subject,
    mediaURL,
  } = values
  const { senderIds, templateMergeFieldValues } = useAppSelector(getDetails)
  const { hasNzMms } = useAppSelector(getUserMmsFeature)
  const { accepted } = useAppSelector(getUserSupportedFileTypes)

  const getSenderIdNumber = () => _find(senderIds, { number: values.senderId })?.number

  const renderSubject = useCallback(() => {
    if (tabMessageContent === TAB_MMS && subject) {
      const parsedSubject = parseTagsForPreview({
        message: subject,
        phoneSource,
        templateMergeFieldValues,
      })?.join(' ').trim()
      return (
        <>
          <Text className="preview-subject">
            {parsedSubject}
          </Text>
          <br />
        </>
      )
    }
    return null
  }, [
    subject, phoneSource, templateMergeFieldValues,
    tabMessageContent,
  ])

  const messageContent = useCallback(() => {
    const sentence = parseTagsForPreview({
      message: tabMessageContent === TAB_SMS ? messageContentSMS : messageContentMMS,
      phoneSource,
      templateMergeFieldValues,
    })

    return !_isEmpty(sentence) ? (
      // eslint-disable-next-line
      <Fragment>
        { sentence?.map(
          (words, wordIndex) => (
            // eslint-disable-next-line
            <Fragment key={wordIndex}>
              {
                words.split(' ').map((word, sentenceIndex) => (
                  // eslint-disable-next-line
                  <Fragment key={sentenceIndex}>
                    <Linker word={word}>
                      <EmptySpace word={word} />
                    </Linker>
                    {' '}
                  </Fragment>
                ))
              }
              <br />
            </Fragment>
          ))}
      </Fragment>
    ) : null
  }, [
    messageContentSMS, messageContentMMS, phoneSource,
    templateMergeFieldValues, tabMessageContent,
  ])

  const isMessageBubbleVisible = messageContent() || (tabMessageContent === TAB_MMS && subject)

  const renderMmsThumbnail = () => {
    if (mediaURL && tabMessageContent === TAB_MMS) {
      let thumbnail = mediaURL
      if (hasNzMms) {
        ACCEPTED_TYPES_NZ.forEach((fileType) => {
          if (mediaURL.includes(fileType)) {
            thumbnail = _get(NON_IMAGE_PREVIEW_ALT, fileType, mediaURL)
          }
        })
      } else {
        accepted.forEach((fileType) => {
          if (mediaURL.includes(fileType)) {
            thumbnail = _get(NON_IMAGE_PREVIEW_ALT, fileType, mediaURL)
          }
        })
      }

      return <img className="mmsImage" width="100%" src={thumbnail} alt="media" />
    }
    return null
  }

  return (
    <div className="phone-preview" data-test="phone-preview">
      <PhoneFrame className="phone-frame" />
      <Space direction="vertical" className="text-center phone-number-container">
        <Text className="phone-number gray">{getSenderIdNumber() || DEFAULT_VALUE}</Text>
      </Space>
      <div className="phone-message-content">
        {isMessageBubbleVisible && (
          <span className="message-bubble">
            { renderSubject() }
            <Text>{messageContent()}</Text>
          </span>
        )}
        <div>
          {renderMmsThumbnail()}
        </div>
      </div>
    </div>
  )
}

export default PhonePreview
