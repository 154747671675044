const BASE_PERSONALISATION_FIELDS = [
  { label: 'personalise.dropdown.mobilenumber', value: 'mobilenumber' },
  { label: 'personalise.dropdown.firstname', value: 'firstname' },
  { label: 'personalise.dropdown.surname', value: 'surname' },
  { label: 'personalise.dropdown.emailaddress', value: 'emailaddress' },
  { label: 'personalise.dropdown.dateofbirth', value: 'dateofbirth' },
  { label: 'personalise.dropdown.country', value: 'country' },
  { label: 'personalise.dropdown.location', value: 'location' },
  { label: 'personalise.dropdown.customfield1', value: 'customField1' },
  { label: 'personalise.dropdown.customfield2', value: 'customField2' },
  { label: 'personalise.dropdown.customfield3', value: 'customField3' },
]

export default BASE_PERSONALISATION_FIELDS
