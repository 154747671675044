import { Field, FormSpy } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'

type TParams = {
  field: string
  set?: string
  to?: string
  cb?: () => void
}

const WhenFieldChanges = ({
  field, set = '', to, cb,
} : TParams) => (
  <Field name={set} subscription={{}}>
    {(
      { input: { onChange } },
    ) => (
      <FormSpy subscription={{}}>
        {() => (
          <OnChange name={field}>
            { () => {
              if (to) {
                onChange(to)
              }

              if (cb) {
                cb()
              }
            }}
          </OnChange>
        )}
      </FormSpy>
    )}
  </Field>
)

export default WhenFieldChanges
