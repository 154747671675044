export type TActionType = {
  REQUEST: string,
  SUCCESS: string,
  FAILURE: string,
}

const createActionType = (actionName: string) => ['REQUEST', 'SUCCESS', 'FAILURE'].reduce((actiontypes, status) => ({
  ...actiontypes,
  [status]: `${actionName}_${status}`,
}), {} as TActionType)

export default createActionType
