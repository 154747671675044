import React from 'react'
import { InfoCircleFilled } from '@ant-design/icons'
import Typography from '@sinch-smb/typography'

import './warning-message.css'
import { FormattedMessage } from 'react-intl'

type TWarningMessageProps = {
  message: string | JSX.Element
  isVisible: boolean
}

function WarningMessage({ message, isVisible }: TWarningMessageProps) {
  return isVisible ? (
    <Typography className="warning-message warning">
      <InfoCircleFilled />
      {' '}
      {
        typeof message === 'string'
          ? <FormattedMessage id={message} />
          : message
      }
    </Typography>
  ) : <div />
}

export default WarningMessage
