import React from 'react'
import { Field, useForm, useFormState } from 'react-final-form'
import Card from 'antd/lib/card'
import Tabs from 'antd/lib/tabs'
import Typography from '@sinch-smb/typography'
import withFinalForm from '@sinch-smb/with-final-form'
import { FormattedMessage, useIntl } from 'react-intl'

import { useAppSelector } from '../../../store/hooks'
import { getDetails, getUserMmsFeature } from '../../../redux/contact/extension-contact-selectors'

import checkMMSCapability from '../../../helpers/check-mms-capability/check-mms-capability'

import {
  TFormValues,
  TExtensionContactFormFieldProps,
  TExtensionContactFormErrorState,
} from '../extension-contact-types'
import { TAB_SMS, TAB_MMS } from '../../../constants/extension-contact'
import MessageBox from './message-box'
import './message-content-card.css'
import MmsContainer from '../../mms-container/mms-container'

const { TabPane } = Tabs

const TabsComp = withFinalForm()(Tabs)
TabsComp.displayName = 'TabsComp'
const DEFAULT_TAB = TAB_SMS

export const setMediaError = (err?: string) => (prev: TExtensionContactFormErrorState) => ({ ...prev, mediaFile: err })

function MessageContentCard(props: TExtensionContactFormFieldProps) {
  const { change } = useForm()
  const { values } = useFormState<TFormValues>()
  const { senderIds } = useAppSelector(getDetails)
  const { hasMms, hasNzMms } = useAppSelector(getUserMmsFeature)
  const [isMmsCapable, setIsMmsCapable] = React.useState<boolean>(false)
  const intl = useIntl()

  React.useEffect(() => {
    setIsMmsCapable(
      !!(hasMms || hasNzMms)
      && checkMMSCapability(senderIds, values.senderId as string),
    )
  }, [senderIds, values.senderId, hasMms, hasNzMms])

  return (
    <Card
      className="card message-content-card"
      title={intl.formatMessage({ id: 'message-box.message-content.label' })}
      data-test="message-content-card"
    >
      <Typography className="gray no-margin">
        <FormattedMessage
          id="message-content.card.content"
        />
      </Typography>

      <Field name="tabMessageContent" defaultValue={DEFAULT_TAB}>
        {(fieldProps) => (
          <TabsComp {...fieldProps} defaultActiveKey={DEFAULT_TAB} activeKey={values.tabMessageContent}>
            <TabPane tab={intl.formatMessage({ id: 'message-content.card.tab.sms' })} key={TAB_SMS}>
              <MessageBox
                {...props}
                type="SMS"
                replaceMessage={(newContent) => change('messageContentSMS', newContent)}
              />
            </TabPane>

            <TabPane
              tab={
                intl.formatMessage(
                  { id: 'message-content.card.tab.mms' },
                  {
                    availability: values.tabMessageContent === TAB_SMS && !isMmsCapable
                      ? intl.formatMessage({ id: 'message-content.card.tab.mms.unavailable' })
                      : '',
                  },
                )
              }
              key={TAB_MMS}
              disabled={!isMmsCapable}
            >
              <MmsContainer
                {...props}
                isMmsCapable={isMmsCapable}
                processing={props.processing}
                default={props.default}
                isNZ={hasNzMms as boolean}
              />
            </TabPane>
          </TabsComp>
        )}
      </Field>
    </Card>
  )
}

export default MessageContentCard
