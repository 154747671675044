/**
 * Returns contatenated personalised text to message content
 * @param personalisedText - personalised/tag text (e.g. #firstname#)
 * @param content - message content
 */
const concatPersonalisedTextToContent = (
  personalisedText: string,
  content?: string,
) => {
  const newContent = content
    ? content.concat(personalisedText)
    : personalisedText
  return newContent
}

export default concatPersonalisedTextToContent
