import React from 'react'
import { Typography } from 'antd'
import Layout from 'antd/lib/layout'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

const { Content } = Layout
const { Title, Text } = Typography

const Error404 = () => (
  <Layout className="layout">
    <Content className="content">
      <div className="site-layout-content">
        <Title>
          <FormattedMessage id="error.page.not-found.title" />
        </Title>
        <Text>
          <FormattedMessage
            id="error.page.not-found.text"
            values={{
              link: <Link to="/"><FormattedMessage id="error.page.not-found.text.link" /></Link>,
            }}
          />
        </Text>
      </div>
    </Content>
  </Layout>
)

export default Error404
