import React from 'react'
import { Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { FormattedMessage, FormattedPlural, useIntl } from 'react-intl'

import {
  smsConfig,
  messageLength,
  multiMessageLength,
  TMessageLengthKey,
} from '../../helpers/sms-counter/sms-counter'
import getCountDescription from '../../helpers/get-count-description/get-count-description'
import { messageCounter } from '../../helpers/sms-counter/message-counter'

import BASE_PERSONALISATION_FIELDS from '../../constants/personalisation-field'

import './pure-sms-character-counter.css'
import { useAppSelector } from '../../store/hooks'
import { getEnableShortTrackableLinks } from '../../redux/contact/extension-contact-selectors'

type TTooltipProps = {
  showTooltip: boolean
  content: string | JSX.Element
}

type TSmsCharacterCountViewProps = {
  message: string
  name: string
}

const TT = (props: TTooltipProps) => props.showTooltip && (
<Tooltip title={props.content} placement="right">
  <InfoCircleOutlined className="help-icon" />
</Tooltip>
)

export const MemoTT = React.memo<TTooltipProps>(TT as React.FunctionComponent)

export const SmsCharacterCountView = (props: TSmsCharacterCountViewProps) => {
  const intl = useIntl()
  const {
    message,
  } = props
  const enableShortTrackableLinks = useAppSelector(getEnableShortTrackableLinks)
  const counter = messageCounter(message, enableShortTrackableLinks)
  const joinPersonalisedFields = BASE_PERSONALISATION_FIELDS.map((f) => `#${f.value}#`).join('|')
  const personalisedFieldsRegex = RegExp(`(${joinPersonalisedFields})`)
  const approximatelyCountText = personalisedFieldsRegex.test(message) ? <span>~</span> : null

  const textLength = counter.length
  const messageCount = counter.messages
  const content = getCountDescription({
    isExtended: counter.encoding === smsConfig.UTF16,
    messageLength: messageLength[counter.encoding as TMessageLengthKey],
    multiMessageLength: multiMessageLength[counter.encoding as TMessageLengthKey],
  })

  const renderCharacterCount = () => (
    <>
      {approximatelyCountText}
      {textLength}
      {' '}
      <FormattedPlural
        one={intl.formatMessage({ id: 'pure-sms-character-counter.character.count.one' })}
        other={intl.formatMessage({ id: 'pure-sms-character-counter.character.count.other' })}
        zero={intl.formatMessage({ id: 'pure-sms-character-counter.character.count.zero' })}
        value={textLength}
      />
      {' '}
      |
    </>
  )

  const renderMessageCount = () => (
    <span>
      {' '}
      {approximatelyCountText}
      {messageCount}
      {' '}
      <FormattedMessage
        id="pure-sms-character-counter.message.count"
      />
      <MemoTT showTooltip content={content} />
    </span>
  )

  return (
    <div data-test={`${props.name}-sms-counter`} id="vwo-sms-counter">
      <div data-test={props.name}>
        <span className="letter-count">
          <span data-test={`${props.name}-character`}>
            {renderCharacterCount()}
            {renderMessageCount()}
          </span>
        </span>
      </div>
    </div>
  )
}

export default SmsCharacterCountView
