import { charCounterWithUrlShortener } from '../url/url'

type TSmsConfig = {
  gsm7bitChars: string
  gsm7bitExChar: string
  GSM_7BIT: string
  GSM_7BIT_EX: string
  UTF16: string
  gsm7bitRegExp: string | RegExp
  gsm7bitExRegExp: string | RegExp
  gsm7bitExOnlyRegExp: string | RegExp
}

export type TMessageLengthKey = keyof typeof messageLength

export const smsConfig: TSmsConfig = {
  gsm7bitChars: "@£$¥èéùìòÇ\\nØø\\rÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ !\\\"#¤%&'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà",
  gsm7bitExChar: '\\^{}\\\\\\[~\\]|€',
  GSM_7BIT: 'GSM_7BIT',
  GSM_7BIT_EX: 'GSM_7BIT_EX',
  UTF16: 'UTF16',
  gsm7bitRegExp: '',
  gsm7bitExRegExp: '',
  gsm7bitExOnlyRegExp: '',
}

smsConfig.gsm7bitRegExp = RegExp(`^[${smsConfig.gsm7bitChars}]*$`)
smsConfig.gsm7bitExRegExp = RegExp(`^[${smsConfig.gsm7bitChars}${smsConfig.gsm7bitExChar}]*$`)
smsConfig.gsm7bitExOnlyRegExp = RegExp(`^[\\${smsConfig.gsm7bitExChar}]*$`)

export const messageLength = {
  GSM_7BIT: 160,
  GSM_7BIT_EX: 160,
  UTF16: 70,
}

export const multiMessageLength = {
  GSM_7BIT: 153,
  GSM_7BIT_EX: 153,
  UTF16: 67,
}

export const detectEncoding = (text: string) => {
  switch (false) {
    case text?.match(smsConfig.gsm7bitRegExp) == null:
      return smsConfig.GSM_7BIT
    case text?.match(smsConfig.gsm7bitExRegExp) == null:
      return smsConfig.GSM_7BIT_EX
    default:
      return smsConfig.UTF16
  }
}

const countGsm7bitEx = (text: string) => (
  text.split('').reduce((total, char) => (
    total + (char.match(smsConfig.gsm7bitExOnlyRegExp) ? 1 : 0)
  ), 0)
)

const count = (txt = '', shortenderUrl = false) => {
  const encoding = detectEncoding(txt)
  let txtLength = txt?.length
  let text = txt

  if (shortenderUrl) {
    const shortenerTxt = charCounterWithUrlShortener(txt)

    txtLength = shortenerTxt.length
    text = shortenerTxt.truncatedTxt
  }

  if (encoding === smsConfig.GSM_7BIT_EX) {
    txtLength += countGsm7bitEx(text)
  }
  let perMessage = messageLength[encoding as TMessageLengthKey]
  if (txtLength > perMessage) {
    perMessage = multiMessageLength[encoding as TMessageLengthKey]
  }
  const messages = Math.ceil(txtLength / perMessage)
  let remaining = (perMessage * messages) - txtLength
  if (remaining === 0 && messages === 0) {
    remaining = perMessage
  }
  return {
    encoding,
    length: txtLength,
    perMessage,
    remaining,
    messages,
  }
}

export default count
